import React, {useEffect} from "react";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Layout from "../../components/Layout/Layout";
import SugarCookingPage from "../sugar-cooking/SugarCookingPage";
import ColorAnalyzerPage from "../color-analyzer/ColorAnayzerPage";
import MoistureAnalyzer from "../moisture-analyzer/MoistureAnalyzer";

import { equipment } from "../../resources/routes"

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const EquipmentsPage = ({ view, setLocale }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        switch (view) {
            case equipment.sugar: {
                setValue(0);
                break;
            }
            case equipment.color: {
                setValue(1);
                break;
            }
            case equipment.moisture: {
                setValue(2);
                break;
            }
            default: {
                break;
            }
        }
    }, [view]);

    const  TabPanel = props => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={index}
                aria-labelledby={index}
                {...other}
            >
                {value === index && <div>{children}</div>}
            </div>
        );
    };

    return (
        <Layout setLocale={setLocale}>
            <div className="equipment-content-wrapper">
                <Paper className={classes.root}>
                    <TabPanel value={value} index={0}>
                        <SugarCookingPage />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ColorAnalyzerPage />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <MoistureAnalyzer />
                    </TabPanel>
                </Paper>
            </div>
        </Layout>
    )
};

export default EquipmentsPage
