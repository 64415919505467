import React from "react";
import Layout from "../../components/Layout/Layout";
import ZOND_NIR from "../../assets/images/v2/microplastic/png/ZOND_NIR-1.png";
import {appRoutes} from "../../resources/routes";
import {HashLink as Link} from "react-router-hash-link";

const MicroplasticPage = ({setLocale}) => {
    return (
        <Layout setLocale={setLocale}>
            <div className="microplastic-content">
                <Link to={appRoutes.microplastic}>
                    Back
                </Link>
                <div className="microplastic--row with-image">
                    <div className="microplastic-page-title">
                        <div className="microplastic-image">
                            <img className="img-1" src={ZOND_NIR} alt='' style={{}}/>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
};

export default MicroplasticPage