import React, {useState} from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";

import { appRoutes, equipment } from "../../resources/routes";

import HomePage from "../../pages/home/HomePage";
import EquipmentsPage from "../../pages/equipment/EquipmentPage";
import MassecuiteProcessingPage from "../../pages/massecuite-processing/MassecuiteProcessingPage"
import MicroplasticPage from "../../pages/microplastic-detection/MicroplasticPageV2"
import MicroplasticSub1 from "../../pages/microplastic-detection/sub1"
import MicroplasticSub2 from "../../pages/microplastic-detection/sub2"
import MicroplasticSub3 from "../../pages/microplastic-detection/sub3"
import ContactsPage from "../../pages/contacts/ContactsPage";
import AboutPage from "../../pages/about/About";
import {translations} from "../../localeInitializer";

function App() {
  const [locale, setLocale] = useState('ua')

  return (
      <IntlProvider locale={locale} messages={translations[locale]}>
          <Router>
              <Route exact path={appRoutes.root} render={() => <HomePage setLocale={setLocale} />}/>
              <Route exact path={appRoutes.equipments} render={() => <EquipmentsPage setLocale={setLocale} />} />
              <Route exact path={appRoutes.colorAnalyzer} render={() => <EquipmentsPage setLocale={setLocale} view={equipment.color} />} />
              <Route exact path={appRoutes.moistureAnalyzer} render={() => <EquipmentsPage setLocale={setLocale} view={equipment.moisture} />} />
              <Route exact path={appRoutes.sugarCooking} render={() => <EquipmentsPage setLocale={setLocale} view={equipment.sugar} />} />
              <Route exact path={appRoutes.massecuite} render={() => <MassecuiteProcessingPage setLocale={setLocale} />} />
              <Route exact path={appRoutes.contacts} render={() => <ContactsPage setLocale={setLocale} />} />
              <Route exact path={appRoutes.about} render={() => <AboutPage setLocale={setLocale} />} />
              <Route exact path={appRoutes.microplastic} render={() => <MicroplasticPage setLocale={setLocale} />} />
              <Route exact path={appRoutes.microplasticSub1} render={() => <MicroplasticSub1 setLocale={setLocale} />} />
              <Route exact path={appRoutes.microplasticSub2} render={() => <MicroplasticSub2 setLocale={setLocale} />} />
              <Route exact path={appRoutes.microplasticSub3} render={() => <MicroplasticSub3 setLocale={setLocale} />} />
              {/*<Route path="/utfel-photo" component={UtfelProcessingPhoto} />*/}
              {/*<Route path="/process-automation" component={ProcessAutomation} />*/}
              {/*<Route path="/prices" component={Prices} />*/}
              {/*<Route path="/contacts" component={Contacts} />*/}
          </Router>
      </IntlProvider>
  );
}

export default App;
