import React from "react";

import Layout from "../../components/Layout/Layout";
import {FormattedMessage as Message} from "react-intl";

// eslint-disable-next-line
import {LocalPhone, LocationOn} from "@material-ui/icons";


const AboutPage = ({ setLocale }) => {
    return (
        <Layout title={<Message id="contacts.about" />} setLocale={setLocale}>
            <div className="about-content">
                <div className="about-content-block">

                    <div className="divTable blueTable">
                        <div className="divTableHeading">
                            <div className="divTableRow">
                                <div className="divTableHead"><Message id="about.head1" /></div>
                                <div className="divTableHead"><Message id="about.head2" /></div>
                                <div className="divTableHead"><Message id="about.head3" /></div>
                                <div className="divTableHead"><Message id="about.head4" /></div>
                            </div>
                        </div>
                        <div className="divTableBody">
                            <div className="divTableRow">
                                <div className="divTableCell">
                                    <Message id="about.1row.1" />
                                    <br/>
                                    <Message id="about.1row.1_1" />
                                </div>
                                <div className="divTableCell"><Message id="about.1row.2" /></div>
                                <div className="divTableCell">
                                    <Message id="about.1row.3" />
                                    <br/>
                                    <Message id="about.1row.3_1" />
                                    <br/>
                                    <a href="https://uapatents.com/1-108831-pristrijj-dlya-vimiryuvannya-koncentraci-kristaliv-cukru-v-utfelyakh.html"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >
                                        patent
                                    </a>
                                </div>
                                <div className="divTableCell">
                                    <Message id="about.1row.4" /><br/>
                                    <Message id="about.1row.4_1" /><br/>
                                    <Message id="about.1row.4_2" /><br/>
                                    <Message id="about.1row.4_3" />
                                </div>
                            </div>

                            <div className="divTableRow">
                                <div className="divTableCell">
                                    <Message id="about.2row.1" />
                                    <br/>
                                    <Message id="about.2row.1_1" />
                                </div>
                                <div className="divTableCell"><Message id="about.2row.2" /></div>
                                <div className="divTableCell">
                                    <Message id="about.2row.3" />
                                    <br/>
                                    <Message id="about.2row.3_1" />
                                    <br/>
                                    <a href="https://uapatents.com/1-108831-pristrijj-dlya-vimiryuvannya-koncentraci-kristaliv-cukru-v-utfelyakh.html"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >
                                        patent
                                    </a>
                                </div>
                                <div className="divTableCell">
                                    <Message id="about.2row.4" /><br/>
                                    <Message id="about.2row.4_1" /><br/>
                                    <Message id="about.2row.4_2" /><br/>
                                    <Message id="about.2row.4_3" />
                                </div>
                            </div>

                            <div className="divTableRow">
                                <div className="divTableCell">
                                    <Message id="about.3row.1" />
                                    <br/>
                                    <Message id="about.3row.1_1" />
                                </div>
                                <div className="divTableCell"><Message id="about.3row.2" /></div>
                                <div className="divTableCell">
                                    <Message id="about.3row.3" /> <a href="https://www.scopus.com/authid/detail.uri?authorId=57203380294"
                                                                     target="_blank"
                                                                     rel="noopener noreferrer"
                                                                    >
                                                                        link
                                                                    </a>
                                    <br/>
                                    <Message id="about.3row.3_1" /> <a href="https://base.uipv.org/searchINV/search.php?action=viewsearchres&page=1"
                                                                       target="_blank"
                                                                       rel="noopener noreferrer"
                                >
                                    link
                                </a>
                                    <br/>

                                </div>
                                <div className="divTableCell">
                                    <Message id="about.3row.4" /><br/>
                                    <Message id="about.3row.4_1" /> <a href="https://sites.google.com/chnu.edu.ua/yermolenko"
                                                                       target="_blank"
                                                                       rel="noopener noreferrer"
                                >link
                                </a><br/>
                                </div>
                            </div>

                            <div className="divTableRow">
                                <div className="divTableCell">
                                    <Message id="about.4row.1" />
                                    <br/>
                                    <Message id="about.4row.1_1" />
                                </div>
                                <div className="divTableCell"><Message id="about.4row.2" /></div>
                                <div className="divTableCell">
                                    <Message id="about.4row.3" /> <a href="https://www.scopus.com/authid/detail.uri?authorId=57203380294"
                                                                     target="_blank"
                                                                     rel="noopener noreferrer"
                                >link
                                </a>
                                    <br/>

                                </div>
                                <div className="divTableCell">
                                    <Message id="about.4row.4" /><br/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
};

export default AboutPage
