import React from "react";
import {FormattedMessage as Message} from "react-intl";

import Paper from "@material-ui/core/Paper";
import schema from "../../../assets/images/v2/cvetomer/cvetomer-schema.png"
import image_1 from "../../../assets/images/v2/cvetomer/cvetomer-img-1.png"
import image_2 from "../../../assets/images/v2/cvetomer/cvetomer-2.png"
import image_3 from "../../../assets/images/v2/cvetomer/cvetomer-3.png"
import image_4 from "../../../assets/images/v2/cvetomer/cvetomer-4.png"
import image_5 from "../../../assets/images/v2/cvetomer/cvetomer-5.png"
import image_6 from "../../../assets/images/v2/cvetomer/cvetomer-6.png"

const Tab3 = () => {
    return (
        <div className="massecuite-tab-content-wrapper">
            <Paper>



                <div className="massecuite-schema-wrapper">
                    <div className="cvetomer-page-headline">
                        <Message id="cvetomer.headline"/>
                    </div>
                    <div className="cvetomer-page-text">
                        <Message id="cvetomer.text.1" values={{
                            red: (chunks) => <strong style={{ color: "red" }}>{chunks}</strong>
                        }}/>
                    </div>

                    <div className="cvetomer-page-text">
                        <Message id="cvetomer.text.2" values={{
                            b: (chunks) => <strong>{chunks}</strong>
                        }}/>
                    </div>

                    <div className="cvetomer-page-text">
                        <Message id="cvetomer.text.3" values={{
                            b: (chunks) => <strong>{chunks}</strong>
                        }}/>
                    </div>

                    <div className="cvetomer-page-headline">
                        <Message id="cvetomer.headline.2"/>
                    </div>

                    <div className="cvetomer-page-text">
                        <Message id="cvetomer.text.4"/>
                        <br/>
                        <Message id="cvetomer.text.5"/>
                        <br/>
                        <Message id="cvetomer.text.6"/>
                    </div>

                    <div className="cvetomer-page-headline">
                        <Message id="cvetomer.headline.3"/>
                    </div>

                    <div className="cvetomer-image">
                        <img className="cvetomer-schema" src={schema} alt='' style={{}}/>
                    </div>

                    <div className="cvetomer-image">
                        <img className="cvetomer-schema" src={image_1} alt='' style={{}}/>
                    </div>

                    <div className="cvetomer-page-headline">
                        <Message id="cvetomer.headline.4"/>
                    </div>

                    <div className="cvetomer-image">
                        <img className="cvetomer-schema" src={image_2} alt='' style={{}}/>
                    </div>

                    <div className="cvetomer-image">
                        <img className="cvetomer-schema" src={image_3} alt='' style={{}}/>
                    </div>

                    <div className="cvetomer-page-text">
                        <Message id="cvetomer.text.7"/>
                    </div>


                    <div className="cvetomer-image" style={{"margin-top": "100px", "margin-bottom": "100px"}}>
                        <img className="cvetomer-schema" src={image_4} alt='' style={{}}/>
                    </div>

                    <div className="cvetomer-page-headline">
                        <Message id="cvetomer.headline.5"/>
                    </div>

                    <div className="cvetomer-image">
                        <img className="cvetomer-schema" src={image_5} alt='' style={{}}/>
                    </div>

                    <div className="cvetomer-page-text">
                        <Message id="cvetomer.text.8"/>
                    </div>

                    <div className="cvetomer-image">
                        <img className="cvetomer-schema" src={image_6} alt='' style={{}}/>
                    </div>

                    <div className="cvetomer-page-text">
                        <Message id="cvetomer.text.9"/>
                    </div>

                </div>
            </Paper>
        </div>
    )
}

export default Tab3