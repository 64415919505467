import React from "react";
import TopBar from "./Topbar"
import Menu from "./Menu";

const Header = ({ setLocale }) => {
    return (
        <section className="prom__layout--header">
            <TopBar setLocale={setLocale} />
            <Menu />
        </section>
    )
};

export default Header
