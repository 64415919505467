import React from "react";
import Layout from "../../components/Layout/Layout";
import {appRoutes} from "../../resources/routes";
import {HashLink as Link} from "react-router-hash-link";
import ANALYZER_1 from "../../assets/images/v2/microplastic/png/Polarimetry_analyzer-1.png";
import ANALYZER_2 from "../../assets/images/v2/microplastic/png/Polarimetry_analyzer-2.png";

const MicroplasticPage = ({setLocale}) => {
    return (
        <Layout setLocale={setLocale}>
            <div className="microplastic-content">
                <Link to={appRoutes.microplastic}>
                    Back
                </Link>
                <div className="microplastic--row with-image">
                    <div className="microplastic-page-title">
                        <img className="img-1" src={ANALYZER_1} alt='' style={{}}/>
                        <img className="img-2" src={ANALYZER_2} alt='' style={{}}/>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default MicroplasticPage