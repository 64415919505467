import React from "react";
import { FormattedMessage as Message } from "react-intl";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import Layout from "../../components/Layout/Layout";

import Tab1 from "./tabs/tab-1"
import Tab2 from "./tabs/tab-2"
import Tab3 from "./tabs/tab-3"
// import Tab4 from "./tabs/tab-4"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        height: "100%",
    },
    tabs: {
        borderRight: `3px solid #fe5a0e`,
        paddingRight: "24px",
    },
}));

const MassecuiteProcessingPage = ({ setLocale }) => {
    const [value, setValue] = React.useState(0);

    const classes = useStyles();

    const  TabPanel = props => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={index}
                aria-labelledby={index}
                {...other}
            >
                {value === index && <div>{children}</div>}
            </div>
        );
    };
    return (
        <Layout title={<Message id="massecuite.title" />} setLocale={setLocale}>
            <div className={`massecuite-content ${classes.root}`}>
                <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    className={classes.tabs}
                    indicatorColor="primary"
                >
                    <Tab label={<Message id="massecuite.tab-1.menu-item" />} />
                    <Tab label={<Message id="massecuite.tab-3.menu-item" />} />
                    <Tab label={<Message id="massecuite.tab-2.menu-item" />} />
                    {/* <Tab label={<Message id="massecuite.tab-3.menu-item" />} />
                    <Tab label={<Message id="massecuite.tab-4.menu-item" />} /> */}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Tab1 />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Tab2 />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Tab3 />
                </TabPanel>
            </div>
        </Layout>
    )
};

export default MassecuiteProcessingPage
