import React from "react";
import { FormattedMessage as Message } from "react-intl";

import image_1 from "../../assets/images/v2/varka/varka-1.png"
import image_2 from "../../assets/images/v2/varka/varka-2.png"
import image_3 from "../../assets/images/v2/varka/varka-3.png"
import image_4 from "../../assets/images/v2/varka/varka-4.png"
import image_5 from "../../assets/images/v2/varka/varka-5.png"
import image_6 from "../../assets/images/v2/varka/varka-6.png"
import image_7 from "../../assets/images/v2/varka/varka-7.png"
import image_8 from "../../assets/images/v2/varka/varka-8.png"
import image_9 from "../../assets/images/v2/varka/varka-9.png"
import patent from "../../assets/images/v2/varka/patent.png"
import yt_image from "../../assets/images/yt-image.jpg"

const SugarCookingPage = () => {
    return (
        <div className="sugar-content">
            <div className="sugar-content--row">
                <div className="moisture-content--row with-image">
                    <div className="sugar-cooking-page-title">
                        <Message id="sugar-cooking.page.title" />
                    </div>
                    <div className="sugar-cooking-page-sub-title">
                        <Message id="sugar-cooking.page.sub.title.1"
                        values={{
                            b: (chunks) => <strong style={{ color: "red" }}>{chunks}</strong>
                        }}
                        />
                        <br/>
                        <Message id="sugar-cooking.page.sub.title.2" />
                        <br/>
                        <Message id="sugar-cooking.page.sub.title.3" />
                        <br/>
                        <Message id="sugar-cooking.page.sub.title.4" />
                        <br/>
                        <Message id="sugar-cooking.page.sub.title.5" />
                        <br/>
                        <b><Message id="sugar-cooking.page.sub.title.6" /></b>
                    </div>
                    <div className="sugar-cooking-page-paragraph">
                        <ul>
                            <li>
                                <Message id="sugar-cooking.page.paragraph.1" />
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.paragraph.2" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }} />
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.paragraph.3" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }} />
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.paragraph.4"/>
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.paragraph.5"values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }} />
                            </li>
                        </ul>

                        <div className="sugar-cooking-page-princip-robotu-title">
                            <Message id="sugar-cooking.page.princip.robotu.title"/>
                        </div>

                        <ul>
                            <li>
                                <a href="https://uapatents.com/1-108831-pristrijj-dlya-vimiryuvannya-koncentraci-kristaliv-cukru-v-utfelyakh.html"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <Message id="sugar-cooking.page.patent.1"/>
                                </a>

                            </li>

                            <li>
                                <Message id="sugar-cooking.page.princip.robotu.paragraph.1" />
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.princip.robotu.paragraph.2" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }} />
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.princip.robotu.paragraph.3" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }} />
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.princip.robotu.paragraph.4" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }}/>
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.princip.robotu.paragraph.5"/>
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.princip.robotu.paragraph.6"/>
                            </li>
                            <li>
                                <Message id="sugar-cooking.page.princip.robotu.paragraph.7"/>
                            </li>
                        </ul>

                        <div>
                            <div className="sugar-cooking-page-image-desc">
                                <Message id="sugar-cooking.page.image.desc.1"/>
                            </div>

                            <div className="sugar-cooking-image">
                                <img className="img-varka-1" src={image_1} alt='' style={{}}/>
                            </div>
                        </div>


                        <div>
                            <div className="sugar-cooking-page-image-desc">
                                <Message id="sugar-cooking.page.image.desc.2"/>
                            </div>

                            <div className="sugar-cooking-image">
                                <img className="img-varka-2" src={image_2} alt='' style={{}}/>
                            </div>
                        </div>

                        <div>
                            <div className="sugar-cooking-page-image-desc">
                                <Message id="sugar-cooking.page.image.desc.3"/>
                            </div>

                            <div className="sugar-cooking-image">
                                <img className="img-varka-3" src={image_3} alt='' style={{}}/>
                            </div>
                        </div>

                        <div>
                            <div className="sugar-cooking-page-image-desc">
                                <Message id="sugar-cooking.page.image.desc.4"/>
                            </div>

                            <div className="sugar-cooking-image">
                                <img className="img-varka-4" src={image_4} alt='' style={{}}/>
                            </div>
                            <div className="sugar-cooking-image">
                                <img className="img-varka-5" src={image_5} alt='' style={{}}/>
                            </div>
                        </div>

                        <div>
                            <div className="sugar-cooking-page-image-desc">
                                <Message id="sugar-cooking.page.image.desc.5"/>
                            </div>

                            <ul>
                                <li>
                                    <Message id="sugar-cooking.page.princip.upr.paragraph.1" />
                                </li>
                                <li>
                                    <Message id="sugar-cooking.page.princip.upr.paragraph.2" />
                                </li>
                                <li>
                                    <Message id="sugar-cooking.page.princip.upr.paragraph.3"/>
                                </li>
                                <li>
                                    <Message id="sugar-cooking.page.princip.upr.paragraph.4"/>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <div className="sugar-cooking-page-image-desc">
                                <Message id="sugar-cooking.page.image.desc.6"/>
                            </div>

                            <div className="sugar-cooking-image">
                                <img className="img-varka-6" src={image_6} alt='' style={{}}/>
                            </div>

                            <div>
                                <p><Message id="sugar-cooking.page.optimization.paragraph.1" /></p>
                            </div>
                            <div>
                                <p><Message id="sugar-cooking.page.optimization.paragraph.2" /></p>
                            </div>
                            <div>
                                <p><Message id="sugar-cooking.page.optimization.paragraph.3" /></p>
                            </div>
                            <div>
                                <p><Message id="sugar-cooking.page.optimization.paragraph.4" /></p>
                            </div>
                            <div>
                                <p><Message id="sugar-cooking.page.optimization.paragraph.5" /></p>
                            </div>
                            <div>
                                <p><Message id="sugar-cooking.page.optimization.paragraph.6" /></p>
                            </div>
                            <div>
                                <Message id="sugar-cooking.page.optimization.paragraph.7" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }}/>
                            </div>
                        </div>

                        <div>
                            <div className="sugar-cooking-image">
                                <img className="img-varka-7" src={image_7} alt='' style={{}}/>
                            </div>
                            <div>
                                <Message id="sugar-cooking.page.optimization.image.desc.1" values={{
                                    b: (chunks) => <strong>{chunks}</strong>,
                                    violet: (chunks) => <strong style={{ color: "darkviolet" }}>{chunks}</strong>,
                                    red: (chunks) => <strong style={{ color: "red" }}>{chunks}</strong>,
                                    blue: (chunks) => <strong style={{ color: "blue" }}>{chunks}</strong>
                                }}/>
                            </div>
                        </div>

                        <div>
                            <div className="sugar-cooking-image">
                                <img className="img-varka-8" src={image_8} alt='' style={{}}/>
                            </div>
                            <div>
                                <Message id="sugar-cooking.page.optimization.image.desc.2" values={{
                                    b: (chunks) => <strong>{chunks}</strong>,
                                    green: (chunks) => <strong style={{ color: "green" }}>{chunks}</strong>,
                                    red: (chunks) => <strong style={{ color: "red" }}>{chunks}</strong>,
                                    blue: (chunks) => <strong style={{ color: "blue" }}>{chunks}</strong>
                                }}/>
                            </div>
                        </div>

                        <div>
                            <div className="sugar-cooking-image">
                                <img className="img-varka-9" src={image_9} alt='' style={{}}/>
                            </div>
                            <div>
                                <Message id="sugar-cooking.page.optimization.image.desc.3" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }}/>
                            </div>
                        </div>

                        <div>
                            <div className="sugar-cooking-page-image-desc">
                                <Message id="sugar-cooking.page.tech.diff.desc"/>
                            </div>

                            <ul>
                                <li>
                                    <Message id="sugar-cooking.page.tech.diff.paragraph.1" values={{
                                        b: (chunks) => <strong>{chunks}</strong>
                                    }} />
                                </li>
                                <li>
                                    <Message id="sugar-cooking.page.tech.diff.paragraph.2" values={{
                                        b: (chunks) => <strong>{chunks}</strong>
                                    }} />
                                </li>
                                <li>
                                    <Message id="sugar-cooking.page.tech.diff.paragraph.3" values={{
                                        b: (chunks) => <strong>{chunks}</strong>
                                    }}/>
                                </li>
                                <li>
                                    <Message id="sugar-cooking.page.tech.diff.paragraph.4"/>
                                </li>
                                <li>
                                    <Message id="sugar-cooking.page.tech.diff.paragraph.5" values={{
                                        b: (chunks) => <strong>{chunks}</strong>
                                    }}/>
                                </li>
                            </ul>
                        </div>
                        <div className="video-block" id="video-block">
                            <h1><Message id="video-block-headline" values={{
                                b: (chunks) => <strong>{chunks}</strong>
                            }}/></h1>

                            <div className="video-1">
                                <div><h3><Message id="video-1.text.1"/></h3></div>
                                <div><Message id="video-1.text.2"/></div>
                                <div><Message id="video-1.text.3"/></div>
                                <div><Message id="video-1.text.4"/></div>
                                <div><Message id="video-1.text.5" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }}/></div>
                                <a href="https://www.youtube.com/watch?v=fbBiOb_eGkw&t=4006s" target="_blank" rel="noopener noreferrer"><img className="sugar-cooking-image" src={yt_image} alt=''/></a>
                                <a href="https://www.youtube.com/watch?v=OKpSPuHkJgc&t=167s" target="_blank" rel="noopener noreferrer">RU</a>
                            </div>

                            <div className="video-2">
                                <div><h3><Message id="video-2.text.1"/></h3></div>
                                <div><Message id="video-2.text.2"/></div>
                                <div><Message id="video-2.text.3"/></div>
                                <div><Message id="video-2.text.4"/></div>
                                <div><Message id="video-2.text.5" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }}/></div>
                                <a href="https://www.youtube.com/watch?v=Ar6FnuHgk0M&t=75s" target="_blank" rel="noopener noreferrer"><img className="sugar-cooking-image" src={yt_image} alt=''/></a>
                                <a href="https://www.youtube.com/watch?v=9hz6LO8kCjc&t=194s" target="_blank" rel="noopener noreferrer">RU</a>
                            </div>

                            <div className="video-3">
                                <div><h3><Message id="video-3.text.1"/></h3></div>
                                <div><Message id="video-3.text.2"/></div>
                                <div><Message id="video-3.text.3"/></div>
                                <div><Message id="video-3.text.4"/></div>
                                <div><Message id="video-3.text.5"/></div>
                                <div><Message id="video-3.text.6" values={{
                                    b: (chunks) => <strong>{chunks}</strong>
                                }}/></div>
                                <a href="https://www.youtube.com/watch?v=V6DL4YcF7g0&t=488s" target="_blank" rel="noopener noreferrer"><img className="sugar-cooking-image" src={yt_image} alt=''/></a>
                                <a href="https://www.youtube.com/watch?v=V-Wjyxue7fk&t=202s" target="_blank" rel="noopener noreferrer">RU</a>
                            </div>
                        </div>

                        <div className="sugar-cooking-image">
                            <img className="patent" src={patent} alt='' style={{}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SugarCookingPage