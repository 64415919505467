import React from "react";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children, title, setLocale }) => {
    return (
        <>
            <Header setLocale={setLocale} />
            <section className="prom__layout--content">
                {title && <span className="prom__layout--content-title">{title}</span>}
                {children}
            </section>
            <Footer />
        </>
    )
};

export default Layout
