import React from "react";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage as Message } from "react-intl";

import { appRoutes } from "../../resources/routes";
import { view } from "../../resources/initials";

const Menu = () => {
    const history = useHistory();
    const path = history.location.pathname.split("/")[1];

    return (
        <section className="prom__layout--header_navigation">
            <ul className="nav prom-navigation">
                <li className={`nav-item prom-navigation-link${path === view.root ? " active" : ""}`}>
                    <Link to={appRoutes.root}>
                        <Message id="home.title" />
                    </Link>
                </li>
                {/* <li className={`nav-item prom-navigation-link${path === view.equipments ? " active" : ""}`}>
                    <Link to={appRoutes.equipments}>
                        <Message id="equipments.title" />
                    </Link>
                </li>
                <li className={`nav-item prom-navigation-link${path === view.massecuite ? " active" : ""}`}>
                    <Link to={appRoutes.massecuite}>
                        <Message id="massecuite.title" />
                    </Link>
                </li> */}
                <li className={`nav-item prom-navigation-link${path === view.contacts ? " active" : ""}`}>
                    <Link to={appRoutes.contacts}>
                        <Message id="contacts.title" />
                    </Link>
                </li>
                <li className={`nav-item prom-navigation-link${path === view.about ? " active" : ""}`}>
                    <Link to={appRoutes.about}>
                        <Message id="contacts.about" />
                    </Link>
                </li>
            </ul>
        </section>
    )
};

export default Menu