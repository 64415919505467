import en from "./translations/en"
import ua from "./translations/ua"
import ru from "./translations/ru"
import pl from "./translations/pl"

export const translations = { en, ua, ru, pl };

export const getLocale = () => {
  const browserLocale = navigator.language.split(/[-_]/)[0];
  const hasTranslations = Boolean(translations[browserLocale]);

  return hasTranslations ? browserLocale : "ua";
};
