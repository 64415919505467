import React from "react";
import {FormattedMessage as Message} from "react-intl";
import { HashLink as Link } from 'react-router-hash-link';
import Layout from "../../components/Layout/Layout";
import {appRoutes} from "../../resources/routes";

const MicroplasticPage = ({setLocale}) => {
    return (
        <Layout setLocale={setLocale}>
            <div className="microplastic-content">
                <div className="microplastic--row with-image">
                    <div className="microplastic-page-title">
                        <Message id="microplastic.page.headline" />
                    </div>

                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p1" />
                    </div>
                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p2" />
                    </div>
                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p3" />
                    </div>
                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p4" />
                    </div>
                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p5" />
                    </div>
                    <ul className="microplastic-page-list">
                        <li><Message id="microplastic.page.text.list1" /></li>
                        <li><Message id="microplastic.page.text.list2" /></li>
                        <li><Message id="microplastic.page.text.list3" /></li>
                    </ul>

                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p6" />
                    </div>
                    <ul className="microplastic-page-list">
                        <li><Message id="microplastic.page.text.list4" /></li>
                        <li><Message id="microplastic.page.text.list5" /></li>
                    </ul>

                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p7" />
                    </div>
                    <div className="microplastic-page-link">
                        <Link to={appRoutes.microplasticSub1}>
                            <Message id="microplastic.page.link1" />
                        </Link>
                    </div>
                    <div className="microplastic-page-link">
                        <Link to={appRoutes.microplasticSub2}>
                            <Message id="microplastic.page.link2" />
                        </Link>
                    </div>
                    <div className="microplastic-page-link">
                        <Link to={appRoutes.microplasticSub3}>
                            <Message id="microplastic.page.link3" />
                        </Link>
                    </div>

                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p8" />
                    </div>
                    <div className="microplastic-page-link">
                        <Link to={appRoutes.sugarCooking}>
                            Sugar cooking
                        </Link>
                    </div>
                    <div className="microplastic-page-link">
                        <Link to={appRoutes.massecuite}>
                            Massecuite processing
                        </Link>
                    </div>
                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p9" />
                    </div>
                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p10" />
                    </div>
                    <ul className="microplastic-page-list">
                        <li><Message id="microplastic.page.text.list6" /></li>
                        <li><Message id="microplastic.page.text.list7" /></li>
                        <li><Message id="microplastic.page.text.list8" /></li>
                    </ul>

                    <div className="microplastic-page-text">
                        <Message id="microplastic.page.text.p11" />
                    </div>

                </div>
            </div>
        </Layout>
    )
};

export default MicroplasticPage