import React, {useState} from "react";

import { LocationOn, LocalPhone, Mail, LanguageOutlined } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import logo from "../../assets/images/prom-opecs-logo.png";
//import ru from "../../assets/images/ru.svg";
import en from "../../assets/images/en.svg";
import ua from "../../assets/images/ua.svg";
import {FormattedMessage as Message} from "react-intl";

const TopBar = ({ setLocale }) => {
    // eslint-disable-next-line
    const [lang, setLang] = useState('ua')
    const handleLangChange = (lang) => {
        setLang(lang)
        setLocale(lang)
    }
    return (
        <section className="prom__layout--header_top-bar">
            <div className="top-bar__logo">
                <img src={logo} alt={"Prom-opecs logo"} />
            </div>
            <div className="top-bar__address">
                <span className="top-bar__address--row">
                    <span className="top-bar__address--row_icon">
                        <LocationOn />
                    </span>
                    <span className="top-bar__address--row_text">
                         <Message id="commons.address.ua.country" /> <br/>
                         <Message id="commons.address.ua.city" />
                    </span>
                </span>
                <span className="top-bar__address--row">
                    <span className="top-bar__address--row_icon">
                        <LocalPhone />
                    </span>
                    <span className="top-bar__address--row_text">
                        <a href="tel:+380372515489">+38-0372-515489</a>
                    </span>
                    <span className="top-bar__address--row_text">
                        <a href="tel:+380372515489">+38-050-2047840 </a>
                    </span>
                </span>
                <span className="top-bar__email--row">
                    <span className="top-bar__email--row_icon">
                        <Mail />
                    </span>
                    <span className="top-bar__email--row_text">
                        <a href={"mailto:promopecs@gmail.com"}>promopecs@gmail.com</a>
                    </span>
                </span>
                <span className="top-bar__lang--row">
                    <span className="top-bar__lang--row_icon">
                        <LanguageOutlined />
                    </span>
                    <span className="top-bar__lang--row_text">
                        <Button size="small" onClick={() => handleLangChange('ru')}>
                            {"РУС"}
                        </Button>

                        <Button size="small" onClick={() => handleLangChange('en')}>
                            {<img src={en} alt="English" height={20} />}
                        </Button>

                        <Button size="small" onClick={() => handleLangChange('ua')}>
                            {<img src={ua} alt="Ukrainian" height={20} />}
                        </Button>
                    </span>
                </span>
            </div>
            {/* <div className="top-bar__address">
                <span className="top-bar__address--row">
                    <span className="top-bar__address--row_icon">
                        <LocationOn />
                    </span>
                    <span className="top-bar__address--row_text">
                        <Message id="commons.address.ru.country" /> <br/>
                         <Message id="commons.address.ru.city" />
                    </span>
                </span>
                <span className="top-bar__address--row">
                    <span className="top-bar__address--row_icon">
                        <LocalPhone />
                    </span>
                    <span className="top-bar__address--row_text">
                        <a href="tel:+74723255936">+7-47232-56331</a>
                    </span>
                    <span className="top-bar__address--row_text">
                        <a href="tel:+380372515489">+7-906-6006983</a>
                    </span>
                </span>
                <span className="top-bar__email--row">
                    <span className="top-bar__email--row_icon">
                        <Mail />
                    </span>
                    <span className="top-bar__email--row_text">
                        <a href={"mailto:volkovwn@rambler.ru"}>volkovwn@rambler.ru</a>
                    </span>
                </span>
            </div> */}
            {/* <div className="top-bar__email">
                <span className="top-bar__email--row">
                    <span className="top-bar__email--row_icon">
                        <Mail />
                    </span>
                    <span className="top-bar__email--row_text">
                        <a href={"mailto:promopecs@gmail.com"}>promopecs@gmail.com</a>
                    </span>
                </span>
            </div> */}
        </section>
    )
};

export default TopBar
