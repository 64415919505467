import React from "react";
import Paper from '@material-ui/core/Paper';

import Layout from "../../components/Layout/Layout";
import {FormattedMessage as Message} from "react-intl";
import {LocalPhone, LocationOn} from "@material-ui/icons";


const ContactsPage = ({ setLocale }) => {
    return (
        <Layout title={<Message id="contacts.title" />} setLocale={setLocale}>
            <div className="contacts-content">
                <div className="contacts-content-block">
                    <Paper>
                        <div className="contacts__address">
                            <span className="contacts__address--row">
                                <span className="contacts__address--row_icon">
                                    <LocationOn />
                                </span>
                                <span className="contacts__address--row_text">
                                     <Message id="commons.address.ua.country" /> <br/>
                                     <Message id="commons.address.ua.city" />
                                </span>
                            </span>
                            <span className="contacts__address--row">
                                <span className="contacts__address--row_icon">
                                    <LocalPhone />
                                </span>
                                <span className="contacts__address--row_text">
                                    <a href="tel:+380372515489">+38-0372-515489</a>
                                </span>
                            </span>
                            <span className="contacts__address--map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2654.9277575435503!2d25.92807651565415!3d48.28500567923546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473408a32c25bb39%3A0x5aea6ee4f32c964!2z0LLRg9C70LjRhtGPINCc0LDRgtGA0L7RgdC-0LLQsCwgMjAsINCn0LXRgNC90ZbQstGG0ZYsINCn0LXRgNC90ZbQstC10YbRjNC60LAg0L7QsdC70LDRgdGC0YwsIDU4MDAw!5e0!3m2!1suk!2sua!4v1588151886324!5m2!1suk!2sua"
                                    width="352"
                                    height="350"
                                    title={<Message id="commons.address.ua.country" />}
                                    frameBorder="0"
                                    style={{border: 0}}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                                />
                            </span>
                        </div>
                    </Paper>
                </div>
                {/* <div className="contacts-content-block">
                    <Paper>
                        <Paper>
                            <div className="contacts__address">
                                <span className="contacts__address--row">
                                    <span className="contacts__address--row_icon">
                                        <LocationOn />
                                    </span>
                                    <span className="contacts__address--row_text">
                                         <Message id="commons.address.ru.country" /> <br/>
                                         <Message id="commons.address.ru.city" />
                                    </span>
                                </span>
                                <span className="contacts__address--row">
                                    <span className="contacts__address--row_icon">
                                        <LocalPhone />
                                    </span>
                                    <span className="contacts__address--row_text">
                                        <a href="tel:+380372515489">+7-47232-55936</a>
                                    </span>
                                </span>
                                <span className="contacts__address--map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40219.762894542095!2d37.77940365444907!3d50.947110341114524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x41244b17c1ee06bf%3A0x6d2fc366da3fdb87!2z0KfQtdGA0L3Rj9C90LrQsCwg0JHRlNC70LPQvtGA0L7QtNGB0YzQutCwINC-0LHQu9Cw0YHRgtGMLCDQoNC-0YHRltGP!5e0!3m2!1suk!2sua!4v1588152243285!5m2!1suk!2sua"
                                        width="352"
                                        height="350"
                                        title={<Message id="commons.address.ru.country" />}
                                        frameBorder="0"
                                        style={{border :0}}
                                        allowFullScreen=""
                                        aria-hidden="false"
                                        tabIndex="0"
                                    />
                                </span>
                            </div>
                        </Paper>
                    </Paper>
                </div> */}
            </div>
        </Layout>
    )
};

export default ContactsPage
