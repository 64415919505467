import React from "react";
import Layout from "../../components/Layout/Layout";
import DISTANC_ANALIZ from "../../assets/images/v2/microplastic/png/DISTANC_ANALIZ-1.png";
import {appRoutes} from "../../resources/routes";
import {HashLink as Link} from "react-router-hash-link";

const MicroplasticPage = ({setLocale}) => {
    return (
        <Layout setLocale={setLocale}>
            <div className="microplastic-content">
                <Link to={appRoutes.microplastic}>
                    Back
                </Link>
                <div className="microplastic--row with-image">
                    <div className="microplastic-page-title">
                        <img className="img-1" src={DISTANC_ANALIZ} alt='' style={{}}/>
                    </div>

                </div>
            </div>
        </Layout>
    )
};

export default MicroplasticPage