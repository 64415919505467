export const appRoutes = {
    root: "/",
    equipments: "/equipments",
    colorAnalyzer: "/equipments/color-analyser",
    moistureAnalyzer: "/equipments/moisture-analyser",
    sugarCooking: "/equipments/sugar-cooking",
    sugarCookingVideo: "/equipments/sugar-cooking#video-block",
    massecuite: "/massecuite-processing",
    microplastic: "/microplastic-detection",
    microplasticSub1: "/microplastic-detection/sub1",
    microplasticSub2: "/microplastic-detection/sub2",
    microplasticSub3: "/microplastic-detection/sub3",
    contacts: "/contacts",
    about: "/about",
};

export const equipment = {
    sugar: "SUGAR",
    color: "COLOR",
    moisture: "MOISTURE"
}