import React from "react";

import Paper from "@material-ui/core/Paper";
import image1 from "../../../assets/images/article/1.svg";
import image2 from "../../../assets/images/article/2.svg";
import image3 from "../../../assets/images/article/3.svg";
import image4 from "../../../assets/images/article/4.svg";
import image5 from "../../../assets/images/article/5.svg";
import image6 from "../../../assets/images/article/6.svg";
import image7 from "../../../assets/images/article/7.svg";

const Tab2 = () => {
    return (
        <div className="massecuite-tab-content-wrapper">
            <Paper>
            <div className="massecuite-tab-content">
                    <img className="article-sugar-slides" src={image1} alt=''/>
                    <img className="article-sugar-slides" src={image2} alt=''/>
                    <img className="article-sugar-slides" src={image3} alt=''/>
                    <img className="article-sugar-slides" src={image4} alt=''/>
                    <img className="article-sugar-slides" src={image5} alt=''/>
                    <img className="article-sugar-slides" src={image6} alt=''/>
                    <img className="article-sugar-slides" src={image7} alt=''/>
                </div>
            </Paper>
        </div>
    )
};

export default Tab2