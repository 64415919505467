import React from "react";

import Paper from "@material-ui/core/Paper";
import {FormattedMessage as Message} from "react-intl";
import image_1 from "../../../assets/images/v2/automation/automation-1.png";
import image_2 from "../../../assets/images/v2/automation/automation-2.png";
import image_3 from "../../../assets/images/v2/automation/automation-3.png";
import image_4 from "../../../assets/images/v2/automation/automation-4.png";
import image_5 from "../../../assets/images/v2/automation/automation-5.png";
import image_6 from "../../../assets/images/v2/automation/automation-6.png";

const Tab1 = () => {
    return (
        <div className="massecuite-tab-content-wrapper">
            <Paper>
                <div className="massecuite-tab-content">
                    <div className="automation-page-headline">
                        <Message id="automation.headline" values={{
                            b: (chunks) => <strong style={{}}>{chunks}</strong>
                        }}/>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.1" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.2" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.3" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.4" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.5" />
                    </div>

                    <div className="automation-page-text">
                        <ol>
                            <li><Message id="automation.text.list1.1" /></li>
                            <li><Message id="automation.text.list1.2" /></li>
                            <li><Message id="automation.text.list1.3" /></li>
                            <li><Message id="automation.text.list1.4" /></li>
                            <li><Message id="automation.text.list1.5" /></li>
                        </ol>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.6" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.7" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.8" />
                    </div>

                    <div className="automation-page-headline">
                        <Message id="automation.headline.2" values={{
                            b: (chunks) => <strong style={{}}>{chunks}</strong>
                        }}/>
                    </div>


                    <div className="automation-page-text">
                        <Message id="automation.text.9" />
                    </div>

                    <div className="automation-page-text">
                        <ol>
                            <li><Message id="automation.text.list2.1" /></li>
                            <li><Message id="automation.text.list2.2" /></li>
                            <li><Message id="automation.text.list2.3" /></li>
                            <li><Message id="automation.text.list2.4" /></li>
                            <li><Message id="automation.text.list2.5" /></li>
                        </ol>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.10" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.11" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.12" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.13" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.14" />
                    </div>

                    <div className="automation-page-text">
                        <ol>
                            <li><Message id="automation.text.list3.1" values={{
                                b: (chunks) => <strong style={{}}>{chunks}</strong>
                            }}/></li>
                        </ol>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.15" values={{
                            b: (chunks) => <strong style={{}}>{chunks}</strong>
                        }}/>
                    </div>

                    <div className="automation-page-text">
                        <ol>
                            <li><Message id="automation.text.list3.2" values={{
                                b: (chunks) => <strong style={{}}>{chunks}</strong>
                            }}/></li>
                        </ol>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.16"/>
                    </div>

                    <div className="automation-page-text">
                        <ol>
                            <li><Message id="automation.text.list3.3" values={{
                                b: (chunks) => <strong style={{}}>{chunks}</strong>
                            }}/></li>
                        </ol>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.17"/>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.18"/>
                    </div>

                    <div className="automation-page-text">
                        <ul>
                            <li><Message id="automation.text.list4.1" /></li>
                            <li><Message id="automation.text.list4.2" /></li>
                            <li><Message id="automation.text.list4.3" /></li>
                            <li><Message id="automation.text.list4.4" /></li>
                            <li><Message id="automation.text.list4.5" /></li>
                            <li><Message id="automation.text.list4.6" /></li>
                            <li><Message id="automation.text.list4.7" /></li>
                        </ul>
                    </div>

                    <div className="automation-page-headline">
                        <Message id="automation.headline.3" values={{
                            b: (chunks) => <strong style={{}}>{chunks}</strong>
                        }}/>
                    </div>

                    <div className="automation-page-text">
                        <ul>
                            <li><Message id="automation.text.list4.8" /></li>
                            <li><Message id="automation.text.list4.9" /></li>
                            <li><Message id="automation.text.list4.10" /></li>
                            <li><Message id="automation.text.list4.11" /></li>
                            <li><Message id="automation.text.list4.12" /></li>
                            <li><Message id="automation.text.list4.13" /></li>
                            <li><Message id="automation.text.list4.14" /></li>
                            <li><Message id="automation.text.list4.15" /></li>
                            <li><Message id="automation.text.list4.16" /></li>
                            <li><Message id="automation.text.list4.17" /></li>
                            <li><Message id="automation.text.list4.18" /></li>
                            <li><Message id="automation.text.list4.19" /></li>
                            <li><Message id="automation.text.list4.20" /></li>
                            <li><Message id="automation.text.list4.21" /></li>
                            <li><Message id="automation.text.list4.22" /></li>
                            <li><Message id="automation.text.list4.23" /></li>
                            <li><Message id="automation.text.list4.24" /></li>
                            <li><Message id="automation.text.list4.25" /></li>
                            <li><Message id="automation.text.list4.26" /></li>
                            <li><Message id="automation.text.list4.27" /></li>
                            <li><Message id="automation.text.list4.28" /></li>
                            <li><Message id="automation.text.list4.29" /></li>
                            <li><Message id="automation.text.list4.30" /></li>
                            <li><Message id="automation.text.list4.31" /></li>
                            <li><Message id="automation.text.list4.32" /></li>
                            <li><Message id="automation.text.list4.33" /></li>
                            <li><Message id="automation.text.list4.34" /></li>
                            <li><Message id="automation.text.list4.35" /></li>
                            <li><Message id="automation.text.list4.36" /></li>
                            <li><Message id="automation.text.list4.37" /></li>
                            <li><Message id="automation.text.list4.38" /></li>
                            <li><Message id="automation.text.list4.39" /></li>
                            <li><Message id="automation.text.list4.40" /></li>
                            <li><Message id="automation.text.list4.41" /></li>
                            <li><Message id="automation.text.list4.42" /></li>
                            <li><Message id="automation.text.list4.43" /></li>
                        </ul>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.19" />

                        <div className="automation-page-image">
                            <img className="img-1" src={image_1} alt='' style={{}}/>
                        </div>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.20" />

                        <div className="automation-page-image">
                            <img className="img-2" src={image_2} alt='' style={{}}/>
                        </div>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.21" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.22" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.23" />

                        <div className="automation-page-image">
                            <img className="img-3" src={image_3} alt='' style={{}}/>
                        </div>

                        <div className="automation-page-image">
                            <img className="img-4" src={image_4} alt='' style={{}}/>
                        </div>
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.24" />
                    </div>

                    <div className="automation-page-text">
                        <Message id="automation.text.25" />
                    </div>

                    <div className="automation-page-image">
                        <img className="img-5" src={image_5} alt='' style={{}}/>
                    </div>

                    <div className="automation-page-image">
                        <img className="img-6" src={image_6} alt='' style={{}}/>
                    </div>

                </div>
            </Paper>
        </div>
    )
}

export default Tab1