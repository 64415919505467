import React from "react";

import Layout from "../../components/Layout/Layout";
import { FormattedMessage as Message } from "react-intl";
import { HashLink as Link } from 'react-router-hash-link';
import { appRoutes } from "../../resources/routes";
import image_1 from "../../assets/images/v2/microplastic/microplastic-main-1.png";
import image_2 from "../../assets/images/v2/microplastic/microplastic-main-2.png";
import automation_image_1 from "../../assets/images/v2/automation/automation-main-1.png";
import automation_image_2 from "../../assets/images/v2/automation/automation-main-2.png";
import varka_1 from "../../assets/images/v2/varka/varka-main-1.png";
import varka_2 from "../../assets/images/v2/varka/varka-main-2.png";

const HomePage = ({ setLocale }) => {
    return (
        <Layout setLocale={setLocale}>
            <div className="home-content">
                <div className="home-content-row">
                    <div className="home-text-container">
                        <div className="prom-title-wrapper">
                            <span className="prom-title">
                                <Message id="home.text-block.title" />
                            </span>
                                <span className="prom-subtitle">
                                <Message id="home.text-block.subtitle" />
                            </span>
                        </div>
                        <span className="prom-text">
                            <Message id="home.text-block.text" />
                        </span>
                    </div>
                    <div className="home-products-container">

                        {/* -- MICROPLASTIC --*/}
                        <div className="home-products-block">
                            <div className="home-products-block--title">
                                <span className="prom-title">
                                    <Message id="microplastic.title" />
                                </span>
                            </div>
                            <div className="home-products-block--text" style={{'text-align': "center"}}>
                                <span className="prom-text">
                                    <Message id="microplastic.description1" />
                                </span>
                            </div>

                            <div className="microplastic-image">
                                <img className="img-1" src={image_1} alt='' style={{}}/>
                                <img className="img-2" src={image_2} alt='' style={{}}/>
                            </div>

                            <div className="home-products-block--more">
                                <Link to={appRoutes.microplastic}>
                                    <span className="more-button">
                                        <Message id="commons.more" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                        {/* -- MICROPLASTIC END --*/}

                        {/* -- SUGAR-COOKING --*/}
                        <div className="home-products-block">
                            <div className="home-products-block--title">
                                <span className="prom-title">
                                    <Message id="sugar-cooking.title" />
                                </span>
                            </div>
                            <div className="home-products-block--text">
                                <span className="prom-text">
                                      <div className="automation-description">
                                          <Message id="sugar-cooking.description1" />
                                      </div>

                                          <div className="automation-image">
                                            <img className="img-3" src={automation_image_1} alt='' style={{"width":"350px"}}/>
                                            <img className="img-4" src={automation_image_2} alt='' style={{"width":"150px"}}/>
                                          </div>
                                </span>
                            </div>
                            <div className="home-products-block--more" >
                                <Link to={appRoutes.sugarCookingVideo}>
                                    <span className="more-button video-button">
                                        <Message id="commons.video" />
                                    </span>
                                </Link>

                                <Link to={appRoutes.sugarCooking}>
                                    <span className="more-button sugar-button">
                                        <Message id="commons.more" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                        {/* -- SUGAR-COOKING END */}

                        {/* -- SUGAR WASHING --*/}
                        <div className="home-products-block">
                            <div className="home-products-block--title">
                                <span className="prom-title">
                                    <Message id="massecuite.title" />
                                </span>
                            </div>
                            <div className="home-products-block--text">
                                <span className="prom-text">
                                    <div className="column">
                                        <Message id="massecuite.description1" />
                                    </div>
                                    <div className="varka-image">
                                        <img className="img-5" src={varka_1} alt='' style={{"width":"375px"}}/>
                                        <img className="img-6" src={varka_2} alt='' style={{}}/>
                                    </div>
                                </span>
                            </div>
                            <div className="home-products-block--more">
                                <Link to={appRoutes.massecuite}>
                                    <span className="more-button">
                                        <Message id="commons.more" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                        {/* -- SUGAR WASHING END --*/}

                        {/* -- AUTOMATION OF SUGAR --*/}
                        <div className="home-products-block">
                            <div className="home-products-block--title">
                                <span className="prom-title">
                                    <Message id="color-analyzer.title" />
                                </span>
                            </div>
                            <div className="home-products-block--text" style={{'text-align': "center"}}>
                                <span className="prom-text">
                                    <Message id="color-analyzer.description1" />
                                    &nbsp;&nbsp;&nbsp;
                                    <Message id="color-analyzer.description2" />
                                    &nbsp;&nbsp;&nbsp;
                                    <Message id="color-analyzer.description3" />
                                    &nbsp;&nbsp;&nbsp;
                                    <Message id="color-analyzer.description4" />
                                    &nbsp;&nbsp;&nbsp;
                                    <Message id="color-analyzer.description5" />
                                    &nbsp;&nbsp;&nbsp;
                                    <Message id="color-analyzer.description6" />
                                </span>
                            </div>
                            <div className="home-products-block--more">
                                <Link to={appRoutes.colorAnalyzer}>
                                    <span className="more-button">
                                        <Message id="commons.more" />
                                    </span>
                                </Link>
                            </div>
                        </div>
                        {/*-- AUTOMATION OF SUGAR END --*/}



                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default HomePage
