import React from "react";

const Footer = () => {
    return (
        <section className="prom__layout--footer">
            <span>2022 &copy; PROM-OPECS</span>
        </section>
    )
};

export default Footer