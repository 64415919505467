import React from "react";
import {FormattedMessage as Message} from "react-intl";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";

import image1 from "../../assets/images/equipment/moisture1.gif";
import image2 from "../../assets/images/equipment/moisture2.jpg";
import image3 from "../../assets/images/equipment/moisture3.jpg";
import image4 from "../../assets/images/equipment/moisture4.jpg";
import image5 from "../../assets/images/equipment/moisture5.jpg";
import image6 from "../../assets/images/equipment/moisture6.jpg";
import image7 from "../../assets/images/equipment/moisture7.jpg";
import image8 from "../../assets/images/equipment/moisture8.jpg";
import image9 from "../../assets/images/equipment/moisture9.jpg";
import image10 from "../../assets/images/equipment/moisture10.jpg";

const MoistureAnalyzerPage = () => {
    return (
        <div className="moisture-content">
            <div className="moisture-content--row">
                <span className="moisture-content--subtitle">
                    <Message id="moisture-analyzer.subtitle_1" />
                </span>
            </div>
            <div className="moisture-content--row">
                <span className="moisture-content--subtitle">
                    <Message id="moisture-analyzer.subtitle_2" />
                </span>
                <span className="moisture-content-paragraph">
                    <Message id="moisture-analyzer.paragraph_1" />
                </span>
            </div>
            <div className="moisture-content--row">
                <span className="moisture-content--subtitle">
                    <Message id="moisture-analyzer.subtitle_3" />
                </span>
                <span className="moisture-content-paragraph">
                    <Message id="moisture-analyzer.paragraph_2" />
                </span>
            </div>
            <div className="moisture-content--row">
                <span className="moisture-content--subtitle">
                    <Message id="moisture-analyzer.subtitle_4" />
                </span>
                <span className="moisture-content-paragraph">
                    <Message id="moisture-analyzer.paragraph_3" />
                </span>
            </div>
            <div className="moisture-content--row with-image">
                <img src={image1} alt={() => <Message id="sugar-cooking.image" />}/>
            </div>
            <div className="moisture-content--row">
                <span className="moisture-content--subtitle">
                    <Message id="moisture-analyzer.subtitle_5" />
                </span>
                <span className="moisture-content-paragraph">
                    <Message id="moisture-analyzer.paragraph_4" />
                </span>
            </div>
            <div className="moisture-content--row">
                <span className="moisture-content--subtitle">
                    <Message id="moisture-analyzer.subtitle_6" />
                </span>
                <div className="moisture-content--list">
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_1" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_2" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_3" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_4" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_5" />
                        </span>
                    </div>
                </div>
            </div>
            <div className="moisture-content--row">
                <span className="moisture-content--subtitle">
                    <Message id="moisture-analyzer.subtitle_7" />
                </span>
                <div className="moisture-content--list">
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image2} alt={() => <Message id="moisture-analyzer.list-item_6" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_6" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image3} alt={() => <Message id="moisture-analyzer.list-item_7" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_7" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image4} alt={() => <Message id="moisture-analyzer.list-item_8" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_8" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image5} alt={() => <Message id="moisture-analyzer.list-item_9" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_9" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image6} alt={() => <Message id="moisture-analyzer.list-item_10" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_10" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image7} alt={() => <Message id="moisture-analyzer.list-item_11" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_11" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image8} alt={() => <Message id="moisture-analyzer.list-item_12" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_12" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image9} alt={() => <Message id="moisture-analyzer.list-item_13" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_13" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image6} alt={() => <Message id="moisture-analyzer.list-item_14" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_14" />
                        </span>
                    </div>
                    <div className="moisture-content--list-item">
                        <span className="moisture-content--list-item--icon">
                            <TurnedInNotIcon />
                        </span>
                        <span className="moisture-content--list-item--image">
                            <img src={image10} alt={() => <Message id="moisture-analyzer.list-item_15" />}/>
                        </span>
                        <span className="moisture-content--list-item--text">
                            <Message id="moisture-analyzer.list-item_15" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MoistureAnalyzerPage