import React from "react";
import {FormattedMessage as Message} from "react-intl";

import image_1 from "../../assets/images/v2/color/color-analyzer1.png"
import image_2 from "../../assets/images/v2/color/color-analyzer2.png"
import image_3 from "../../assets/images/v2/color/color-analyzer3.png"
import image_4 from "../../assets/images/v2/color/color-analyzer4.png"
import image_5 from "../../assets/images/v2/color/color-analyzer5.png"
import image_6 from "../../assets/images/v2/color/color-analyzer6.png"
import image_7 from "../../assets/images/v2/color/color-analyzer7.png"
import image_8 from "../../assets/images/v2/color/color-analyzer8.png"
import image_9 from "../../assets/images/v2/color/color-analyzer9.png"

const ColorAnalyzerPage = () => {
    return (
        <div className="color-content">
            <div className="color-content--row with-image">
                <div className="color-analyzer-page-title">
                    <Message id="color-analyzer.page.headline" />
                </div>

                <div className="color-analyzer-page-text">
                    <Message id="color-analyzer.page.text.1" />
                </div>
                <div className="color-analyzer-page-text">
                    <Message id="color-analyzer.page.text.2" />
                </div>
                <ul className="color-analyzer-page-list">
                    <li><Message id="color-analyzer.page.list1.1" /></li>
                    <li><Message id="color-analyzer.page.list1.2" /></li>
                    <li><Message id="color-analyzer.page.list1.3" /></li>
                    <li><Message id="color-analyzer.page.list1.4" /></li>
                    <li><Message id="color-analyzer.page.list1.5" /></li>
                    <li><Message id="color-analyzer.page.list1.6" /></li>
                </ul>

                <div className="color-analyzer-image">
                    <img className="img-1" src={image_1} alt='' style={{}}/>
                </div>
                <div className="color-analyzer-image">
                    <img className="img-2" src={image_2} alt='' style={{}}/>
                </div>

                <div className="color-analyzer-page">
                    <Message id="color-analyzer.page.text.3" />
                </div>

                <div className="color-analyzer-page-text">
                    <Message id="color-analyzer.page.text.4" />
                </div>
                <ul className="color-analyzer-page-list">
                    <li><Message id="color-analyzer.page.list2.1" /></li>
                    <li><Message id="color-analyzer.page.list2.2" /></li>
                </ul>

                <div className="color-analyzer-image">
                    <img className="img-3" src={image_3} alt='' style={{}}/>
                </div>

                <div className="color-analyzer-page-text">
                    <Message id="color-analyzer.page.text.5" />
                </div>
                <ul className="color-analyzer-page-list">
                    <li><Message id="color-analyzer.page.list3.1" /></li>
                    <li><Message id="color-analyzer.page.list3.2" /></li>
                    <li><Message id="color-analyzer.page.list3.3" /></li>
                    <li><Message id="color-analyzer.page.list3.4" /></li>
                    <li><Message id="color-analyzer.page.list3.5" /></li>
                    <li><Message id="color-analyzer.page.list3.6" /></li>
                    <li><Message id="color-analyzer.page.list3.7" /></li>
                    <li><Message id="color-analyzer.page.list3.8" /></li>
                </ul>

                <div className="color-analyzer-image">
                    <img className="img-4" src={image_4} alt='' style={{}}/>
                </div>
                <div className="color-analyzer-image">
                    <img className="img-5" src={image_5} alt='' style={{}}/>
                </div>
                <div className="color-analyzer-image">
                    <img className="img-6" src={image_6} alt='' style={{}}/>
                </div>

                <div className="color-analyzer-page-text">
                    <Message id="color-analyzer.page.text.6" />
                </div>
                <ul>
                    <li><Message id="color-analyzer.page.list4.1" /></li>
                </ul>

                <div className="color-analyzer-image">
                    <img className="img-7" src={image_7} alt='' style={{}}/>
                </div>
                <div className="color-analyzer-image">
                    <img className="img-8" src={image_8} alt='' style={{}}/>
                </div>
                <div className="color-analyzer-image">
                    <img className="img-9" src={image_9} alt='' style={{}}/>
                </div>
            </div>
        </div>
    )
};

export default ColorAnalyzerPage